@import "../../_globalColor";

.main {
  width: 90%;
  padding: 20px 10px;
  margin: 0px auto;
  margin-top: 4rem;
}
.greet-main {
  width: 90%;
  padding: 20px 10px;
  margin: 0px auto;
}

.flex-center {
  justify-content: center;
}

.subTitle {
  color: $subTitle !important;
}

.greeting-main {
  position: relative;
  display: grid;
  padding-top: 24px;
  grid-template-columns: repeat(auto-fit, minmax(277px, 1fr));
  row-gap: 2rem;
  align-items: center;
}

.button-greeting-div {
  display: flex;
  margin-top: 20px;
}

.greeting-text {
  font-size: 70px;
  line-height: 1.1;
  color: $textColor !important;
}

.greeting-text-p {
  font-size: 30px;
  line-height: 40px;
}

.greeting-image-div > img {
  max-width: 100%;
  height: auto;
}

.greeting-button a {
  min-width: 125px;
}

.wave-emoji {
  animation-duration: 1.8s;
  animation-iteration-count: infinite;
  animation-name: wave;
  display: inline-block;
  transform-origin: 70% 70%;
}

.big-circle {
  height: 100%;
  width: 100%;
  position: relative;
  border: 3px solid #bbbbbb;
  border-radius: 50%;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  animation: Rotate 20s linear infinite;
  -webkit-animation: Rotate 20s linear infinite;
}

.icon-block {
  width: 64px;
  height: 64px;
  position: absolute;
  border-radius: 50%;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  background-color: #fff;
  box-shadow: 0 0 10px #fff;
}

.icon-block img {
  margin: 0px auto;
  width: 60%;
  animation: Rotate-reverse 20s linear infinite;
  -webkit-animation: Rotate-reverse 20s linear infinite;
}

.icon-block:first-child {
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}

.icon-block:nth-child(2) {
  top: 50%;
  right: 0;
  transform: translate(50%, -50%);
  -webkit-transform: translate(50%, -50%);
}

.icon-block:nth-child(3) {
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  -webkit-transform: translate(-50%, 50%);
}

.icon-block:nth-child(4) {
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}

.rounding-sec {
  position: relative;
  height: 320px;
  width: 320px;
  margin: auto;
}

.images {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}

.images {
  margin-left: auto;
  width: 240px;
}
.images img {
  border-radius: 50%;
  box-shadow: 0 0 10px #d1d9e6;
}

.img-w {
  width: 100%;
  height: auto;
}

/* keyframe animation */

@keyframes Rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes Rotate {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes Rotate-reverse {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@-webkit-keyframes Rotate-reverse {
  from {
    -webkit-transform: rotate(360deg);
  }
  to {
    -webkit-transform: rotate(0deg);
  }
}
@keyframes wave {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(-10deg);
  }
  20% {
    transform: rotate(12deg);
  }
  30% {
    transform: rotate(-10deg);
  }
  40% {
    transform: rotate(9deg);
  }
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

/* Media Query */
@media (max-width: 1380px) {
  .greeting-text {
    font-size: 50px;
  }
  .greeting-text-p {
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  .button-greeting-div {
    justify-content: space-around;
  }
  .greeting-text {
    font-size: 30px;
    text-align: center;
  }
  .greeting-text-p {
    font-size: 16px;
    line-height: 2;
    text-align: center;
  }
}

@media (max-width: 720px) {
  .greeting-image-div {
    height: 256px;
  }
}

@media (max-width: 320px) {
  .main {
    width: auto;
  }
}

@media screen and (max-width: 990px) {
  .greeting-main {
    grid-template-columns: repeat(auto-fit, minmax(420px, 1fr));
    overflow: hidden;
    display: flex;
    flex-direction: column-reverse;
  }
  .rounding-sec {
    margin: auto;
  }
}

@media screen and (max-width: 600px) {
  .greeting-main {
    grid-template-columns: repeat(auto-fit, minmax(266px, 1fr));
    overflow: hidden;
    display: flex;
    flex-direction: column-reverse;
  }
  .icon-block {
    width: 50px;
    height: 50px;
  }
  .rounding-sec {
    height: 270px;
    width: 270px;
  }
  .images {
    width: 200px;
  }
}
