.splash-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.splash-animation-container {
  height: 48vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.splash-title-container {
  font-size: 1.5rem;
  text-decoration: none;
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: 10%;
}

.splash-title {
  font-family: "Agustina Regular", cursive;
  font-weight: bold;
  font-variant-ligatures: no-common-ligatures;
  -webkit-font-variant-ligatures: no-common-ligatures;
  padding: 0 20px;
}
